import React from 'react'
import {Link} from 'gatsby';
import cx from 'classnames';
import Styles from '../assets/styles/breadcrumbs.module.css';
import ChevronSvg from './svg/ChevronSvg';

const Breadcrumbs = props => {
  const breadcrumbs = props.breadcrumbs;
  const len = breadcrumbs.length;
  const chevronStyles = {
    display: 'inline-block',
    fontSize: 'inherit',
    height: '1em',
    overflow: 'visible',
    verticalAlign: '-0.125em',
    width: '0.625em',
    color: '#808fa3',
    margin: '0 4px 0 7px'
  }

  if(!breadcrumbs) {
    return null;
  }

  return (<section className={cx(Styles.breadcrumbSection)}>
          <div className={'container'}>
            <div className={'row'}>
            <div className={'col-md-12'}>             
              <nav className={cx(Styles.siteBreadcrumbs)} aria-label="breadcrumb">
                <ol className={cx('breadcrumb', Styles.breadcrumb)}>
                  <Link to={'/'} className={cx('breadcrumb-item', Styles.breadcrumbItem)}>
                    <span className={cx('breadcrumb-link', Styles.breadcrumbLink)}>Marketplace</span>
                  </Link>
                  {breadcrumbs.map((breadcrumb, index) => (
                    <Link key={index} to={breadcrumb.link} className={index===len-1 ? cx(Styles.breadcrumbItem, Styles.active) : Styles.breadcrumbItem}>
                      <ChevronSvg styles={chevronStyles} />
                      {breadcrumb.name}
                    </Link>
                  ))}
                </ol>
              </nav>
              </div>
            </div>
          </div>
        </section>)
};

export default Breadcrumbs;

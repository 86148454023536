import React, { Component } from "react";
import cx from 'classnames';
import Layout from '../components/layout'
import Breadcrumbs from '../components/breadcrumbs'
import Detail from '../components/deals/detail'
import Styles from '../assets/styles/dealDetail.module.css';
import CustomHelmet from '../components/customHelmet'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { graphql } from 'gatsby';
import Cookies from 'universal-cookie';
// import FooterBottomContact from "../components/footerBottomContact";
import allKenticoCloudItemPageDealinfo from "../../data/kentico/allKenticoCloudItemPageDealinfo";
import allKenticoCloudItemLeadSCount from "../../data/kentico/allKenticoCloudItemLeadSCount";

library.add(faChevronLeft)

class DealTemplate extends Component {
  constructor(props) {
    super(props);
    this.dealFeatures = this.dealFeatures.bind(this);
    this.dealInfocontnt = allKenticoCloudItemPageDealinfo;
    this.LeadsCount = allKenticoCloudItemLeadSCount;
    
      this.state = {
        deal:props.data.hashchingDeal,
      breadcrumbs:
      [
        {
          name:'Compare Loans',
          link: 'hashching-home-loan-deals'
        },
        {
          name:'Deal info',
          link: '/deals/'+props.data.hashchingDeal.slug
        }
      ],
      page_headings:
      {
        title:"Negotiated home loan deals that lenders don't advertise anywhere!",
        description:"We have 2000+ brokers in our network. They know the ins and out of the market and can get the best deals out-there. They negotiate for you and will get you unbeatable rates."
      }
    };    
  }
  dealFeatures(features) {
      features = JSON.parse(features);

      if(features && features.length>0)
      {
        return features.map((index,feature) => (
            <li key={index}><span>{index}</span></li>
          ))
      }
    }
  calculateMontlyRepayments(intRate,termRange,mortgageAmount)
  {
    var getDefaultInterestRate = ( intRate / 100 );
    var interestRatePerFrequency = getDefaultInterestRate / 12;
    var A = mortgageAmount;
    var N = 12 * termRange;
    var newRepaymentsAmount = (interestRatePerFrequency * A) / (1 - Math.pow((1 + interestRatePerFrequency), -N));

    if (newRepaymentsAmount== 'Infinity' || newRepaymentsAmount < 1 || isNaN(newRepaymentsAmount)) {
      return 0;
    }
    return Math.round(newRepaymentsAmount);
  }

  render() {
      const dealInfoContent = this.dealInfocontnt.edges[0].node.elements;
      const LeadsCount = this.LeadsCount.edges[0].node.elements;
    
      
      if(this.state.deal) {
        let cookies = new Cookies();
        cookies.set('deal', this.state.deal.deal_id, { path: '/' })
      }

      let breadcrumbs = <Breadcrumbs breadcrumbs={this.state.breadcrumbs} />
      
      if (typeof (Storage) !== "undefined" && sessionStorage.getItem("fromBrokerProfile")) {
        if (!!sessionStorage.getItem('fromBrokerProfile')) {
          let oBreadcrumbs = sessionStorage.getItem("fromBrokerProfile")
          oBreadcrumbs = JSON.parse(oBreadcrumbs);    
          breadcrumbs = <Breadcrumbs breadcrumbs={oBreadcrumbs} />
        }
      }
      
     return ( 
      <Layout>
       <CustomHelmet>
          <title>{this.state.deal.seo_title}</title>
          <meta name="description" content={this.state.deal.meta_description}/>
          <meta name="keywords" content={this.state.deal.focus_keyword}/>          
          <meta property="og:description" content={this.state.deal.meta_description}/>
          <meta property="og:title" content={this.state.deal.seo_title} />
          <meta property="og:url" content={'https://www.hashching.com.au/deals/'+this.state.deal.slug} />          
          <meta name="twitter:description" content={this.state.deal.meta_description} />
          <meta name="twitter:title" content={this.state.deal.seo_title} />
          <link rel="canonical" href={'https://www.hashching.com.au/deals/'+this.state.deal.slug} />
        
     </CustomHelmet>
        
         {breadcrumbs}
        <Detail dealInfo={this.state.deal} dealInfoContent={dealInfoContent} LeadContent={LeadsCount}/>   
        {/* <FooterBottomContact content={'asd'} layout={'full'} inPage={'choose-deal'}></FooterBottomContact>  */}
        </Layout>
    )
  }
}

export default DealTemplate

export const pageQuery = graphql`
  query($id: String!) {
    hashchingDeal(id: { eq: $id }) {
      slug
      deal_id
      interest_rate
      interest_type
      comparison_interest_rate
      loan_to_value_ratio
      application_fees
      ongoinFees
      ongoing_fee_type
      allNewFeatures
      deal_type_id
      minimum_borrow_amount
      meta_description
      seo_title
      focus_keyword
      facebook_title
      facebook_description
      canonical_url      
    }
  }
`;

// removed deal_brokers after canonical_url
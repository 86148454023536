import React, { Component } from "react";
import cx from 'classnames';
import Styles from '../../assets/styles/dealDetail.module.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { navigate } from "gatsby";
import Cookies from 'universal-cookie';
import { removeCookie, host } from '../../helpers/commons'

library.add(faChevronLeft)

class Detaile extends React.Component {
constructor(props) {
    super(props);
    this.saveLaterMessage = '<p>Thanks</p>'
    this.dealFeatures = this.dealFeatures.bind(this);
    this.saveDeal = this.saveDeal.bind(this);    
    this.dealInfoContent = props.dealInfoContent;
    this.singleDeal = props.dealInfo;
    this.LeadCount = props.LeadContent||'46,407';
    this.monthlyRepayment = '';
    /** On page refresh show default value for monthly repayment**/
      const cookiesLoanAmount = new Cookies();
      let myArraymonthlyRepayment = cookiesLoanAmount.get('DealInfoMonthlyRepayment')
      if(!!myArraymonthlyRepayment)
      {
        var myArray = myArraymonthlyRepayment.split('|');
        if(myArray.length==2 && myArray[1]==this.singleDeal.deal_id)
        {
          this.monthlyRepayment=myArray[0];
        }
        else
        {
          let cookies = new Cookies()
          cookies.remove('DealInfoMonthlyRepayment', { path: '/' })
        }
      }
     /*****************************************************/
    this.saveLaterEmail ='';
     }
    onchangeEmail(e)
    {
        this.saveLaterEmail =e.target.value;
    }
  dealFeatures(features) {
      features = JSON.parse(features);

      if(features && features.length>0)
      {
        return features.map((index,feature) => (
            <li key={index}><span>{index}</span></li>
          ))
      }
    }
  calculateMontlyRepayments(intRate,termRange,mortgageAmount)
  {
    var getDefaultInterestRate = ( intRate / 100 );
    var interestRatePerFrequency = getDefaultInterestRate / 12;
    var A = mortgageAmount;
    var N = 12 * termRange;
    var newRepaymentsAmount = (interestRatePerFrequency * A) / (1 - Math.pow((1 + interestRatePerFrequency), -N));

    if (newRepaymentsAmount== 'Infinity' || newRepaymentsAmount < 1 || isNaN(newRepaymentsAmount)) {
      return 0;
    }
    return Math.round(newRepaymentsAmount);
  }

  saveDeal()
  {

      if (!this.saveLaterEmail) {
          toast.error("Please enter your email!")
      }

    if(this.saveLaterEmail)
    {
         if(typeof this.saveLaterEmail !== "undefined")
         {
           let lastAtPos = this.saveLaterEmail.lastIndexOf('@');
           let lastDotPos = this.saveLaterEmail.lastIndexOf('.');

           if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.saveLaterEmail.indexOf('@@') == -1 && lastDotPos > 2 && (this.saveLaterEmail.length - lastDotPos) > 2)) {
              toast.error("Invalid Email !")
              return false;
            }
            else
            {
                axios.post(host + '/gatsby-api/save-for-later', {
                  email: this.saveLaterEmail,
                  type: 'deal',
                  type_id:this.singleDeal.deal_id,
                  data_info:this.singleDeal
                })
                .then((response) => {
                  const cookies = new Cookies();
                   cookies.set('SaveEmail', this.saveLaterEmail, { path: '/' });
                  if(response.data.status=='100')
                  {
                   toast.success('Saved for Later !')       
                  }
                  else
                  {
                    toast.error("Somenthing went wrong !") 
                  }
                })
                .catch((error) => {
                  toast.error("Somenthing went wrong !")        
                });
                 
            }
        }
     
    }
    
  }
  
    cardNavigation(event = null, dealInfo = '') {
      
      if (!!dealInfo && dealInfo.deal_brokers != 'undefined') {
        sessionStorage.setItem('dealBrokers', dealInfo.deal_brokers);
      }

      if (!!this.props.dealClickHandler) {
        this.props.dealClickHandler(dealInfo, 'deal-info');
      } else {
          if (typeof (Storage) !== "undefined") {
            sessionStorage.removeItem("landingData");
          }
          removeCookie('SaveName', { path: '/' })
          removeCookie('funnelData');
          navigate('/loan-enquiry')
      }
    }
     handleBackClick() {
        // event.preventDefault()
        if (typeof window !== 'undefined') {
          window.history.back();
        }
        
      }

  render() {
      const dealInfoContent = this.dealInfoContent;
      const singleDeal = this.singleDeal;
      const leadCount =  this.LeadCount.label_leads_count;
      // console.log(leadCount);

      if(!dealInfoContent || !singleDeal)
      {
        return (<div></div>)
      }
      const alerts = {}
     return (
     <section>
      <section className={Styles.dealInfoSection}>
      <ToastContainer />
            <div className={'container'}>
              <div className={'row'}>
                <div className={cx(Styles.dealInfoLeft, 'col-lg-7')}>
                    <div id="BacktoResultId" className={Styles.gobackbtn}>
                      <a  onClick={this.handleBackClick} className={Styles.gobacklink} href="javascript://">
                          <i className={cx(Styles.fa, Styles.faChevronLeft)} aria-hidden="true">
                          <FontAwesomeIcon icon={faChevronLeft} />
                          </i>
                          <span className={Styles.btntxt}>Back to results</span>
                      </a>
                    </div>

                        <div className={Styles.dealInfoLeftcontnt}>
                        <h1 className={Styles.pageTitle}>{dealInfoContent.label_heading.value}</h1>
                            <div className={Styles.dealsValue}>
                                <span className={Styles.bigDealIcon}>
                                </span>
                                <span className={cx(Styles.varRate, Styles.dealRates)}>
                                <span className={Styles.dealcounts}>
                                 {singleDeal.interest_rate}
                                <span className={Styles.percnt}>
                                % p.a</span></span>
                                <span className={Styles.rateTypTxt}>{singleDeal.interest_type} rate</span>
                                </span>
                                <span className={cx(Styles.compRate, Styles.dealRates)}>
                                <span className={Styles.dealcounts}>
                                {singleDeal.comparison_interest_rate} 
                                <span className={Styles.percnt}>
                                % p.a</span></span>
                                <span className={Styles.rateTypTxt}>
                                {dealInfoContent.label_dealterm1.value}</span>
                                </span>
                            </div>
                            <div className={Styles.dealsListing}>
                                <div className={cx(Styles.dealItem, 'd-flex')}>
                                    <div className={Styles.dealitemInfo}>
                                        <span className={Styles.dealitemTitle}>
                                        Max {singleDeal.loan_to_value_ratio} %</span>
                                        <span className={Styles.dealitemSubtitle}>{dealInfoContent.label_loanratio.value}</span>
                                    </div>
                                    <div className={Styles.dealitemInfo}>
                                        <span className={Styles.dealitemTitle}>
                                        {singleDeal.application_fees}</span>
                                        <span className={Styles.dealitemSubtitle}>{dealInfoContent.label_applicationfee.value}</span>
                                    </div>
                                    <div className={Styles.dealitemInfo}>
                                        <span className={Styles.dealitemTitle}>
                                        {singleDeal.ongoinFees}</span>
                                        <span className={Styles.dealitemSubtitle}>
                                        {dealInfoContent.label_ongoingfee.value}</span>
                                    </div>
                                    <div className={Styles.dealitemInfo} >
                                        <span className={Styles.dealitemTitle}>
                                        {
                                          (this.monthlyRepayment!='') ? '$'+this.monthlyRepayment : '$'+this.calculateMontlyRepayments(singleDeal.interest_rate,25,500000)
                                        }
                                        </span>
                                        <span className={Styles.dealitemSubtitle}>{dealInfoContent.label_monthlyrepayments.value}
                                        {
                                          (this.monthlyRepayment=='') ? '#' : ''
                                        }
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <ul className={Styles.chooseDeallisting}>
                            {this.dealFeatures(singleDeal.allNewFeatures)}
                            </ul>
                            <div className={Styles.chooseDealBtn}>
                                 <a className={cx(Styles.choosedealBtnlink, 'blue-btn btn')} href="javascript:void(0);" onClick={(event) => {
                                     this.cardNavigation(event, singleDeal)
                                 }}>{dealInfoContent.label_button[0].elements.label.value}</a>
                            </div>
                            <div className={Styles.savedealSection}>
                                <label>{dealInfoContent.save_dealforlater[0].elements.label_text.value}</label>
                                <div className={cx(Styles.inputGroup, 'input-group')}>
                                    <input onChange={this.onchangeEmail.bind(this)} type="email" className={cx(Styles.formControl, 'form-control')} placeholder={dealInfoContent.save_dealforlater[0].elements.input_placeholder.value} name="email" />
                                    <span className={cx(Styles.subscribenewsBtnMain, Styles.inputGroupBtn, 'input-group-btn')}>
                                    <button className={cx(Styles.sendBtn, 'btn')} type="submit" onClick={this.saveDeal}>
                                    {dealInfoContent.save_dealforlater[0].elements.button_text.value}
                                    </button>                                    
                                    </span>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className={cx(Styles.dealInfoRight, 'col-lg-5')}>
                        <div className={Styles.dealInfoRightcontnt}>
                            <h4>{dealInfoContent.deal_infodescription[0].elements.label_headingtext1.value}</h4>
                            <p>
                            {dealInfoContent.deal_infodescription[0].elements.label_descriptiontext1.value}
                            </p>
                            <h4>{dealInfoContent.deal_infodescription[0].elements.label_headingtext2.value}</h4>
                            <p>
                            {dealInfoContent.deal_infodescription[0].elements.label_descriptiontext2.value}
                            </p>
                            <h4>{dealInfoContent.deal_infodescription[0].elements.label_headingtext3.value}</h4>
                            <p className={Styles.bestdealcontnt}>
                            {dealInfoContent.deal_infodescription[0].elements.label_descriptiontext3.value} <strong>{dealInfoContent.deal_infodescription[0].elements.label_descriptiontext3postfix.value}</strong>
                            </p>
                            {/* <h4>{dealInfoContent.deal_infodescription[0].elements.label_headingtext4.value}</h4> */}
                            {/* <div className={cx(Styles.dealscounts, 'row')}>
                                <div className={cx('col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5')}>
                                    <h3>{leadCount.value}</h3> 
                                    <h5>{dealInfoContent.deal_infodescription[0].elements.label_hashchingstats[0].elements.line2.value}</h5>
                                </div>
                                <div className={cx('col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7')}>
                                    <h3>{dealInfoContent.deal_infodescription[0].elements.label_hashchingstats[1].elements.line1.value}</h3>
                                    <h5>{dealInfoContent.deal_infodescription[0].elements.label_hashchingstats[1].elements.line2.value}</h5>
                                </div>
                            </div>
                            <div className={cx(Styles.dealscounts, 'row')}>
                                <div className={cx('col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5')}>
                                
                                      <h3>{dealInfoContent.deal_infodescription[0].elements.label_hashchingstats[2].elements.line1.value}</h3>
                                      <h5>{dealInfoContent.deal_infodescription[0].elements.label_hashchingstats[2].elements.line2.value}<br/>{dealInfoContent.deal_infodescription[0].elements.label_hashchingstats[2].elements.line3.value}</h5>
                                  </div>
                                  <div className={cx('col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7')}>
                                 
                                      <h3>{dealInfoContent.deal_infodescription[0].elements.label_hashchingstats[3].elements.line1.value}</h3>
                                      <h5>{dealInfoContent.deal_infodescription[0].elements.label_hashchingstats[3].elements.line2.value}<br/> {dealInfoContent.deal_infodescription[0].elements.label_hashchingstats[3].elements.line3.value}</h5>
                                  </div>
                            </div> */}

                        </div>
                    </div>

                </div>
            </div>

        </section>
        <div className={'container'}>
              <div className={'row'}>
                <div className={'col-12'}>
                  <div className={'fine-print'}>
                    <p className={'fine-print-text'}>
                   
                    </p>
                   
                      <p className={'fine-print-text'}>
                    {
                                        
                     }
                    </p>
                    
                  </div>
              </div>
             </div>
         </div>
         </section>

      )
  }
}
export default Detaile;